(function () {
  'use strict';

  /* @ngdoc object
   * @name home
   * @description
   *
   */
  angular
    .module('home', [
      'ui.router',
      'firebase',
      'angular-md5'
    ]);
}());
